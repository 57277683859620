$(function(){

  $('.overlay').hover(
    function(){
      // hover on
      $(this).find('.slide-up-effect').show();
    },
    function(){
      // hover out
      $(this).find('.slide-up-effect').hide();
      // $(this).find('p').slideDown();
    }
  );


});

$(function(){
    $('.dropdown').hover(function() {
        // find next drop down menu and show it
        $(this).find('.dropdown-menu').addClass('show');
    },
    function() {
        $(this).find('.dropdown-menu').removeClass('show');
    });
});




$(function(){
  // text-shadow: 5px 5px 5px rgba(44,71,112,0.54); on heroHeading after 2 seconds of page load
  function processScroll(){
    var docElem = document.documentElement,
    docBody = document.body,
    scrollTop = docElem['scrollTop'] || docBody['scrollTop'],
      scrollBottom = (docElem['scrollHeight'] || docBody['scrollHeight']) - window.innerHeight,
    scrollPercent = scrollTop / scrollBottom * 100 + '%';

    document.getElementById("progress-bar").style.setProperty("--scrollAmount", scrollPercent);
  }

  //document.addEventListener('scroll', processScroll);
});
